import React from "react";
import Link from "gatsby-link";
import { GatsbyImage } from "gatsby-plugin-image";

export type article = {
  title: string;
  description: string;
  path: string;
  date: string | null;
  img: any;
};

export default function ArticleList(p: { list: article[] }) {
  return (
    <div className="container section">
      {p.list.map((a, index) => (
        <Article {...a} key={index} />
      ))}
    </div>
  );
}

function Article(a: article) {
  return (
    <Link className="shadow round responsiveMargin wrap" to={a.path}>
      <GatsbyImage className="responsiveImg" image={a.img} alt={a.title} />
      <div className="margin flex-content">
        <div className="title">{a.title}</div>
        <div className="has-text-weight-bold subtitle is-size-5">{a.date}</div>
        <div className="subtitle">{a.description}</div>
      </div>
    </Link>
  );
}
